.sidebar {
  position: relative;

  .no-entities {
    display: none;
    padding: 20px;
    margin: 20px 10px;

    code {
      background: #ffe8e8;
      padding: 3px 5px;
      border-radius: 3px;
      color: #e84747;
    }

    a {
      color: var(--primary);
    }

    h4 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
    }
  }

  &.sidebar--open {
    .no-entities {
      display: block;
    }

    .sidebar-item {
      padding: 0;

      a {
        display: flex;
        padding: 15px 0 15px 20px;
        width: 100%;
      }
    }

    ul {
      animation: menuIntro 2s;
    }
  }

  &.sidebar--closed {
    .MuiDrawer-paper {
      width: 87px;
    }

    .MuiListItemText-root,
    .bottom-links {
      display: none;
      opacity: 0;
    }

    .MuiListItemIcon-root {
      justify-content: center;
      min-width: 100%;
    }

    .sidebar-icon {
      height: 35px;
    }

    .sidebar-item {
      margin: 0 auto;

      a {
        width: 100%;
        padding: 10px 30px;
      }
    }

    .MuiListItem-button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .MuiDrawer-paper {
    top: 80px;
    width: 314px;
    height: calc(100% - 80px);
    box-shadow: 1px 0 5px -5px #000;
  }

  .sidebar-icon {
    color: #ba1e43d9;

    #Shape {
      fill: #000;
    }
  }

  .MuiListItemIcon-root {
    min-width: 45px;
    padding: 5px 0;
  }

  .MuiListItemText-root {
    .MuiTypography-body1 {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
    }
  }

  .MuiListItem-button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .item--selected {
    background-color: #fafafa;
  }

  .sidebar-list {
    margin: 0;
    padding: 0;
  }

  .bottom-links {
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 1201;

    .sidebar-bottom-icon {
      vertical-align: -7px;
      margin-right: 10px;
    }

    a {
      color: #000;

      &:hover {
        text-decoration: none;
      }
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 90%;
      height: 1px;
      border-top: 1px solid #eceeef;
      transform: translateX(5%);
    }

    li {
      list-style: none;
      margin: 0;
      padding: 0;
      text-decoration: none;
      color: #000;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 1px;
      cursor: pointer;
      align-self: center;
    }
  }

  @media print {
    display: none;
  }

  @media screen and (max-width: 1440px) {
    top: 65px;

    .MuiDrawer-paper {
      top: 65px;
      height: calc(100% - 65px);
    }

    .MuiListItemText-root {
      .MuiTypography-body1 {
        font-size: 0.75rem;
      }
    }
  }

  @media screen and (max-height: 700px) {
    .bottom-links {
      position: static;
      margin: 20px 0px;
    }
  }

  @media only screen and (max-width: 768px) {
    &.sidebar--closed {
      .MuiDrawer-paper {
        left: -90px;
      }
    }
  }
}

@keyframes menuIntro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
