.upload-dropzone {
  background: #fff;

  & > div:focus {
    outline: none;
  }

  p {
    border: 1px dashed rgba(0, 0, 0, 0.1);
    background: rgba(228, 0, 43, 0.02);
    text-align: center;
    padding: 40px 0;
    outline: none;
    cursor: pointer;
    border-radius: 6px;
  }

  .upload-icon {
    margin: 0 auto 10px auto;
    display: block;
  }

  @media screen and (max-width: 1440px) {
    font-size: 0.875rem;
  }
}

.upload-box {
  .accepted-files {
    display: block;
    color: #777;
    font-size: 14px;
  }

  .files {
    .single-file {
      display: flex;
      flex-direction: row;

      .upload-icon {
        flex: 0;
        margin-right: 10px;
      }

      svg {
        display: inline-block;
        height: 60px;
        font-size: 50px;
        margin-right: 10px;
      }

      .file-content {
        flex: 1;
        align-self: center;

        .file-name {
          margin-bottom: 15px;
        }
      }
    }
  }
}
