.data-search-crud {
  // place all style for this page here
}

.MuiGrid-item.query-builder-holder {
  margin-bottom: 30px;
  
  margin-top: 50px;
  & > div {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
  }
}