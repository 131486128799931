.input-warning {
  svg {
    fill: rgb(230, 230, 68);
    vertical-align: -7px;
    padding-right: 5px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
    color: #000;
  }
}
