.table-widget {
  overflow: hidden;

  .labv-table {
    background: #fff;

    .table-body {
      width: 100%;
      max-width: 100%;
      padding: 20px 0;
    }
  }
}
