.snack-notification {
  &.snack-success {
    border-left: 2px solid var(--successColor);
    border-radius: 6px 0 0 6px;
  }

  &.snack-error {
    border-left: 2px solid var(--errorColor);
    border-radius: 6px 0 0 6px;
  }

  .MuiSnackbarContent-root {
    background: #fff;
    color: #545454;
    min-width: 150px;
  }
}
