.token-definition {
  margin-bottom: 20px;
  display: inline-block;
  padding-right: 30px;

  .grid-head {
    border-bottom: 1px solid #eee;
  }

  .token-box {
    padding: 20px 0;
  }

  .token-name {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;

    h4 {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 300;
  }
}
