.intro-card {
  .intro-container {
    min-height: 100%;
    align-self: center;
  }

  .content-section {
    border-radius: 0 6px 6px 0;
  }

  .intro-intro {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 6px 0px 0px 6px;

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('/images/background-image.png');
      background-position: 100% 100%;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
    }

    .intro-message {
      align-self: center;
      padding: 120px 60px 50px 60px;
      color: #fff;
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .tagline {
      font-weight: 300;
      margin-top: 20px;
    }

    .official-url {
      flex: 0;
      text-align: center;
      padding: 20px 40px;
      margin-bottom: 50px;

      a {
        color: #fff;
        font-weight: 300;
        text-decoration: none;
        font-size: 14px;
      }
    }
  }

  .intro-form {
    background: #fff;
  }
  .forgot-link {
    margin-top: 15px;
    display: block;
    text-align: center;
    text-decoration: underline;
  }
}
