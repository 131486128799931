.tab-name {
  padding: 30px 0;
}
.form-tab .MuiTab-wrapper {
  display: flex;
  flex-direction: row-reverse;
  svg {
    margin-left: 10px;
    fill: #e4002b;
  }
}