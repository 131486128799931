.page-material-details {
  // place all style for this page here
}

.table-cell-view-mode {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}

.table-cell-edit-mode {
  white-space: nowrap;
  width: 300px;
}

.margin-r {
  margin-right: auto
}