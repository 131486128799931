.btn-menu-left.MuiButton-text {
  background: transparent;
  padding: 0 20px;
  border-radius: 0;

  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 24px;
  }
}

.menu-left-wrap {
  text-align: right;
  color: #212121;

  .menu-left-name {
    font-weight: 600;
    font-size: 15px;
    text-transform: capitalize;
  }

  .menu-left-type {
    text-transform: capitalize;
    font-weight: 300;
    opacity: 0.8;
    line-height: 13px;
    font-size: 13px;
  }
}

.arrow {
  margin-left: 15px;
  margin-top: 4px;

  svg {
    width: 10px;
  }
}

.menu-dropdown {
  .MuiPaper-root {
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
    min-width: 150px;

    .menu-icon {
      vertical-align: -2px;
      float: right;
    }

    .MuiMenuItem-gutters {
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 600;
      text-align: left;
      justify-content: center;
      padding: 0;
    }

    .MuiListItem-button {
      a {
        padding: 10px 15px;
        line-height: 25px;
        text-decoration: none;
      }
    }
  }
}
