.login-form-wrap {
  padding: 20px 31px;

  .title {
    width: 20%;
    margin: 50px auto;
  }

  .form-controls {
    width: 100%;
  }

  .login-button {
    margin: 31px 0;
  }

  .forgot-link {
    margin: auto;
  }

  .visited {
    color: #b0640c;
  }
  .caps-lock-warning {
    color: black;
    margin: 0;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
  }
}
