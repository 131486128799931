button.remove-search-field {
  right: 0;
  position: absolute;
  top: 0;
}
.query-builder {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}
.search-field-holder {
  position: relative;
}
.field-selector-popup {
  position: absolute;
  padding: 15px;
  background: #fff;
  z-index: 20;
  border-radius: 5px;
  min-width: 400px;
  box-shadow: 0 10px 20px #efefef;
  border: 1px solid #efefef;
}

.field-selector-field {
  margin-bottom: 15px;

}
.search-datepicker > div {
  margin-top: 4px;
  margin-bottom: 4px;
}
.search-button {
  justify-self: flex-end;
  align-self: center;
}