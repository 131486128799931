.firestarter-header {
  &.firestarter-header--closed {
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.06);

    .brand {
      width: 50px;
      padding: 0 20px 0 0;
    }
  }

  .btn-menu-left {
    height: 100%;
    border-bottom: 3px solid transparent;

    .menu-left-wrap {
      color: #212121;
    }

    &.active {
      color: #212121;
      border-bottom: 3px solid #2c5364;

      .menu-left-wrap {
        color: #212121;
      }

      .MuiButton-startIcon {
        color: #2c5364;
      }
    }

    .MuiButton-startIcon {
      color: #5f6368;
    }
  }

  .brand {
    border-right: 2px #2b3034;
    box-shadow: 2px 0 5px -5px #2b3034;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 60px;
    padding: 0 30px 0 0;
    transition: all 0.2s;

    .brand-logo {
      transition: all 0.2s;
      height: 50%;
    }
  }

  .grow {
    flex-grow: 1;
  }

  .menu-toggle {
    margin-right: 20px;
    // border-right: 1px solid #0000001c;
  }

  .btn-menu {
    padding: 20px;
    margin-left: 13px;
    margin-right: 13px;
  }

  .nav-toggle {
    width: 25px;
  }

  @media print {
    display: none !important;
  }

  @media screen and (max-width: 1440px) {
    max-height: 80px;
  }

  @media only screen and (max-width: 768px) {
    .brand {
      width: 269px;
    }
  }
}
