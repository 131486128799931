.MuiChip-root.chip-active {
  background: var(--successBackground);
  color: var(--successColor);

  .MuiChip-iconSmall {
    color: var(--successColor);
  }
}

.MuiChip-root.chip-inactive {
  background: var(--errorBackground);
  color: var(--errorColor);

  .MuiChip-iconSmall {
    color: var(--errorColor);
  }
}
