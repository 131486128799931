@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  padding: 0;
  margin: 0;
  background: #f4f6f8;
  overflow: scroll;
}

$primaryColor: #2c5364;

:root {
  --primary: #2c5364;
  --errorBackground: #ffe8e8;
  --errorColor: #e84747;
  --successBackground: #e8ffe7;
  --successColor: #33b133;
}

p {
  line-height: 24px;
}

.back-button {
  margin-top: 15px;
  display: block;
}

.pagination {
  flex-shrink: 0;
}

.content-box {
  border-radius: 0 0 6px 6px;
}

.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
  background: #fafafa;
  color: #545454;
}

.MuiPaper-root.form-content {
  border-radius: 0 0 6px 6px;
}

.table-photo {
  display: block;
  background-size: cover;
  background-position: center;
  width: 60px;
  height: 60px;
}

.m-values {
  margin-right: 5px;
}

.MuiButton-root.submit-button {
  margin-top: 30px;
}

.page-title {
  margin-top: 0;
  .title-cell {
    .export-button {
      &.MuiButton-text {
        letter-spacing: unset;
        font-size: 14px;
        font-weight: 400;
        text-transform: unset;
        background: transparent;
      }

      img {
        margin-right: 10px;
      }
    }

    .upload-button {
      margin-left: 20px;
    }
  }
}

@page {
  size: landscape;
}

.no-results {
  margin: 100px auto;
  width: 300px;
  text-align: center;

  h2 {
    color: #bababa;
    text-align: center;
    font-size: 30px;
  }

  &.no-results--small {
    margin: 100px auto;

    h2 {
      font-size: 14px;
      color: #bababa;
      text-align: center;
    }
  }
}

.info-document {
  text-align: center;
  padding: 15px;

  img {
    padding-bottom: 10px;
  }
}

.document-container {
  margin-top: 20px;

  .document-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4rem;
    text-overflow: ellipsis;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.document-container-small {
  .document-button {
    display: block;
    background: none;
    width: 100%;
    justify-content: flex-start;

    &:hover {
      background-color: #e9e9e9;
    }

    .MuiButton-label {
      display: flex;

      .document-name {
        display: inline;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: normal;
        text-transform: none;
        margin-left: 5px;
      }
    }
  }
}

.bottom-toolbar {
  margin-top: 20px;
  display: flex;
  flex-direction: row;

  .grow {
    flex-grow: 1;
  }

  .per-page {
    padding-top: 10px;

    label {
      margin-right: 10px;
      font-size: 14px;
    }

    .MuiSelect-icon {
      top: calc(50% - 16px);
    }

    .MuiSelect-selectMenu {
      padding-bottom: 0;
    }
  }
}

// Global Mui overrides
.MuiPickersToolbarButton-toolbarBtn.MuiButton-text {
  background: transparent;
}

.MuiSelect-root.MuiSelect-select:focus {
  background-color: transparent;
}

.MuiAutocomplete-popper {
  z-index: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.MuiButton-text.btn-cancel {
  display: inline-block;
  margin-top: 30px;
  margin-left: 10px;
}

.format-wrap {
  width: 70px;
  text-align: center;
  font-weight: bold;
  border-radius: 6px;

  img {
    display: inline-block;
    width: 30px;
    vertical-align: -8px;
    margin-right: 9px;
  }

  &.format-wrap--pdf {
    color: #4e4e4e;
  }
}

.gallery-photo {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  border: 1px solid #eee;

  &.gallery-photo--pdf {
    background-size: 15px;
    background-repeat: no-repeat;
  }
}

fieldset {
  border: 1px solid #ccc;
  padding: 15px 25px 15px;
  margin: 20px 0;
  legend {
    background-color: #fff;
    padding: 0 10px;
  }
}

.box-wrap + .box-wrap {
  margin-top: 30px;
}

.MuiFormControl-root.pad-tb-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pad-b-3 {
  padding-bottom: 30px;
}
.box-holder {
  padding: 25px 20px;
  background-color: #fff;
  margin-right: 30px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 4px 0 4px 0 rgba(43, 45, 55, 0.01);
  margin-bottom: 30px;
}

.box-wrapper {
  background-color: transparent !important;
}

/* Scrollbar override */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  border: 5px solid #fff;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.m-r-5 {
  margin-right: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}

.m-r-10 {
  margin-right: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}

.m-r-15 {
  margin-right: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}

.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}

.m-x-5 {
  margin: 0 5px;
}
.m-x-10 {
  margin: 0 10px;
}
.m-x-15 {
  margin: 0 15px;
}

.m-y-5 {
  margin: 5px 0;
}
.m-y-10 {
  margin: 10px 0;
}
.m-y-15 {
  margin: 15px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}