.cell-sorter {
  display: inline-block;
  vertical-align: -7px;
  margin-left: 20px;

  svg {
    display: block;
    width: 10px;
    cursor: pointer;
    opacity: 0.6;
  }

  @media print {
    display: none !important;
  }

  @media screen and (max-width: 1440px) {
    margin-left: 10px;
    width: 7px;
    .arrow-direction-up,
    .arrow-direction-down {
      width: 7px;
    }
  }
}

#table-header {
  flex-basis: auto;
}