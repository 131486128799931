.notification-dropdown {
  .MuiPaper-root {
    max-width: 500px;
    min-width: 500px;
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid rgb(224, 227, 231);
    box-shadow: rgba(170, 180, 190, 0.3) 0px 4px 20px;
    border-radius: 10px;

    .menu-icon {
      vertical-align: -2px;
      float: right;
    }

    .MuiMenuItem-gutters {
      color: #fff;
      text-transform: capitalize;
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      letter-spacing: 1px;
      justify-content: center;
      padding: 0;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .MuiListItem-button {
      a {
        padding: 8px 16px;
        line-height: 25px;
        text-decoration: none;

        p {
          font-size: 13px;
          white-space: break-spaces;
          text-transform: none;
        }
      }
    }
  }

  &__see-all {
  position: sticky !important;
  bottom: 0 !important;
  //width: 100% !important;
  background:#2c5364 !important;
    
    a {
      display: flex;
      justify-content: center;
      color: #fff;
    }
  }
}


.notification-wrap {
  text-align: right;
  color: #212121;

  .notification-name {
    font-weight: 600;
    font-size: 15px;
  }
}
