.audit-list {
  margin: 0;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;

  li {
    margin: 0;
    padding: 5px 0;
    list-style: none;
    font-size: 14px;
    border-top: 1px solid #eee;

    .btn-invisible {
      padding: 0;
      margin: 0;
      background: none;
    }

    .type {
      margin-right: 5px;
      font-size: 10px;
      background: #595959;
      color: #fff;
      padding: 3px 5px;
      border-radius: 6px;
    }

    .date-icon {
      font-size: 14px;
      vertical-align: -3px;
      margin-right: 5px;
    }

    .created-at {
      color: #9e9e9e;
      font-size: 13px;
      text-align: right;
      margin-right: 5px;
    }

    .author {
      font-size: 13px;
      float: right;
    }
  }
}

.audit-modal {
  .field-container {
    border-bottom: 1px solid #eee;
    padding: 10px 0;

    .field-name {
      font-weight: bold;
    }
  }

  .code-view {
    background: #000;
    color: #fff;
    overflow: auto;
    padding: 10px;
    border-radius: 6px;
  }
}
