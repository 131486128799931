.admin {
  background: #f4f6f8;
  .made-with {
    font-size: 12px;
    margin-top: 20px;
    opacity: 0.4;
    line-height: 20px;
  }

  .version-grid {
    text-align: right;

    .version {
      font-size: 12px;
      margin-top: 20px;
      opacity: 0.4;
      line-height: 20px;

      span {
        margin: 0 10px;
        color: #bebebe;
      }
    }
  }

  .content {
    margin: auto;
    margin-top: 40px;
    padding: 30px;
    background: #f4f6f8;
    box-sizing: border-box;

    &.content--open {
      width: 100%;
    }

    @media print {
      top: 0 !important;
      left: 0 !important;
      padding: 0 !important;
      width: 100% !important;
    }

    @media only screen and (max-width: 768px) {
      left: 0;
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      width: 100%;
      left: 0;

      &.content--open {
        width: 100%;
        left: 0;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .content {
      top: 65px;

      &.content--open {
        top: 65px;
      }
    }
  }
}
