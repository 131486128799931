.board-config {
  .MuiPaper-root {
    min-width: 500px;
  }

  .board-list {
    padding: 0;
    margin: 0;
    height: 500px;
    overflow-y: auto;
  }

  .delete-btn {
    float: right;
    margin-top: -2px;

    svg {
      font-size: 20px;
    }
  }
}
