.page-title {
  .page-title__text {
    margin: 15px 0 10px;
  }
  
  .page-title__options {
    text-align: right;

    a,
    button {
      margin-left: 10px;
    }
  }
  padding: 43px 0px;
}
