.role-definition {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;

  .grid-head {
    border-bottom: 1px solid #eee;
  }

  .role-box {
    padding: 20px 0;
  }

  .role-name.MuiGrid-item {
    margin-bottom: 0;
    padding-bottom: 0;

    h4 {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 300;
  }
}
