.alert-dialog {
  margin: auto;
  max-width: 800px;

  .alert-dialog-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
