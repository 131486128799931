.code-wrap {
  pre {
    background: #fafafa;
    overflow: auto;
    padding: 10px;
    max-height: 500px;
  }

  .toggle-visibility {
    float: right;
  }

  .box-toolbar {
    margin-bottom: 10px;
  }

  &.code-wrap--visible {
    .box-toolbar {
      margin-bottom: 30px;
    }
  }
}
