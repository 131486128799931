.dropzone.MuiPaper-elevation1 {
  box-shadow: none;
  background: #f3f3f3;
  padding: 10px;
  border: 2px dashed transparent;
  overflow-y: auto;

  .drop-target {
    height: 100vh;
  }

  .box-toolbar {
    font-size: 14px;
  }

  &.dropzone--over {
    border-color: var(--primary);
  }
}
