.table-column-hide {
  .table-column-hide-items {
    width: 600px;
    height: 300px;
    padding: 15px 20px;

    .table-column-hide-title {
      padding: 9px;
    }
  }
}

.filter-input {
  .MuiPaper-root {
    padding: 0 5px 5px;
  }
}

.datatable {
  overflow: hidden;
  overflow-x: auto;
}
