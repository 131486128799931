.init-form-wrap {
  padding: 100px 35px;

  .language-switch {
    margin: auto;
    text-align: center;

    .language-label {
      color: #000;
    }

    .MuiSwitch-track {
      background-color: #000;
    }

    .MuiSwitch-thumb {
      background-color: #000;
    }
  }

  .init-input {
    margin: 20px 0;
  }

  .init-logo {
    margin: 90px auto 100px auto;
    max-width: 100%;
    display: block;
  }

  .init-button {
    margin-top: 20px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .init-checkbox {
    margin-top: 5px;

    .init-checkbox-label {
      font-weight: 400;
    }
  }

  .forgot-link {
    margin-top: 20px;
    display: block;
  }
  
  .create-admin-title {
    margin-bottom: 55px;
  }
  
  .create-adming-subtitle {
    margin-bottom: 30px;
    opacity: 0.7;
  }
}
