.arrow-direction-up {
  transform: rotate(180deg);
}

.arrow-direction-left {
  transform: rotate(90deg);
}

.arrow-direction-right {
  transform: rotate(-90deg);
}
