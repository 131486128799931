.entity-stats {
  margin: 15px 0;

  .updated-time,
  .created-time {
    font-size: 12px;
    color: #c3c3c3;
    margin-bottom: 5px;
    font-weight: 400;

    span {
      margin-right: 5px;
      color: #afafaf;
    }
  }
}
