.color-picker {
  .color-field {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }

  .picker-wrapper {
    position: absolute;
    z-index: 1001;
  }

  .is-open-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
}
