.accordion-holder {
  width: 100%;
  min-width: 485px;
  margin: 0 30px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  &:first-of-type {
    border-radius: 4px 4px 0 0;
    border-bottom: 0;
    margin-top: 30px;
  }
  &:only-of-type {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-top: 30px;
  }
  &:last-of-type {
    border-radius: 0 0 4px 4px;
    margin-bottom: 30px;
  }
  &:nth-child(2) {
    border-top: 1px solid #e0e0e0;
  }

  .MuiCollapse-container {
    background-color: rgba(224, 224, 224, 0.1);
    border-top: 1px solid #e0e0e0;
  }
}

.information {
  background-color: #2c536410;
  padding: 24px 16px;
  border-radius: 6px;
}

.infoIcon {
  color: #2c5364;
}