.btn-profile.MuiButton-text {
  height: 100%;
  background: transparent;
  letter-spacing: 1px;
  padding: 0px 20px;
  border-left: 2px solid rgba(43, 48, 52, 0.1);
  border-radius: 0px;
}

.profile-wrap {
  text-align: right;
  color: #212121;

  .profile-name {
    font-weight: 600;
    font-size: 15px;
  }

  .profile-type {
    text-transform: capitalize;
    font-weight: 300;
    opacity: 0.8;
    line-height: 13px;
    font-size: 11px;
  }
}

.arrow {
  margin-left: 15px;
  margin-top: 4px;

  svg {
    width: 10px;
  }
}

.user-dropdown {
  .MuiPaper-root {
    border-radius: 0;
    box-shadow: none;
    min-width: 150px;

    .menu-icon {
      vertical-align: -2px;
      float: right;
    }

    .MuiMenuItem-gutters {
      color: #fff;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      letter-spacing: 1px;
      justify-content: center;
      padding: 0;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .MuiListItem-button {
      a {
        padding: 10px 16px;
        line-height: 25px;
        text-decoration: none;
      }
    }
  }
}
