.labv-table {
  background-color: #f4f6f8;
  .table-body {
    background-color: #fff;
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;

    &.MuiTableContainer-root {
      padding: 0;
      width: 100%;
    }

    .table-row:last-child * {
      border-bottom: 0;
      box-shadow: none;
    }
  }
}

.no-results {
  text-align: center;
  width: auto;
}

.sticky-column {
  position: sticky;
  right: 0;
  background-color: white;
  min-width: 150px;
}
