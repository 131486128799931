.media-photo {
  max-width: 100%;
  border-radius: 6px;
}

.data-fields {
  button {
    width: 100%;
  }
}

.component-field-name {
  margin-bottom: 10px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  display: block;
}

.data-actions {
  text-align: right;
}

.data-content {
  line-height: 50px;
  font-weight: 400;
}

.media-wrap {
  position: relative;

  .url-copy {
    display: none;
  }

  .copy-link {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.4);
  }

  .view-link {
    position: absolute;
    top: 10px;
    right: 65px;
    background: rgba(255, 255, 255, 0.4);
  }

  .pdf-preview {
    width: 100%;
    border: 1px solid #eee;
    height: 400px;
  }
}
