.draft-editor-container {
  .DraftEditor-editorContainer {
    height: 400px;
  }

  * {
    font-family: 'Roboto', sans-serif !important;
  }
}

.draft-toolbar-container {
  border: none;
  padding: 5px;
  background: #fafafa;

  .rdw-dropdownoption-default,
  .rdw-dropdown-selectedtext {
    font-family: 'Arial';
  }

  .rdw-inline-wrapper,
  .rdw-block-wrapper,
  .rdw-list-wrapper,
  .rdw-text-align-wrapper,
  .rdw-colorpicker-wrapper,
  .rdw-link-wrapper,
  .rdw-image-wrapper,
  .rdw-history-wrapper {
    margin-bottom: 0;
  }

  .rdw-option-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .rdw-option-wrapper {
    border: none;
    text-decoration: none;
    background: #fafafa;

    &.rdw-option-active {
      box-shadow: none;
      background: #ececec;
    }

    &:hover {
      box-shadow: none;
      background: #f3f3f3;
    }

    img {
      opacity: 0.6;
    }
  }

  .rdw-dropdown-wrapper {
    border: none;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      box-shadow: none;
    }

    .rdw-dropdown-selectedtext {
      opacity: 0.6;
      text-decoration: none;
    }
  }
}
