.search-input .MuiInput-underline {
  &:focus, &:active {
    border-bottom: 2px solid #b0640c;
  }
  &::placeholder {
    color: #212121;
    font-size: 15px;
    line-height: 18px;
    opacity: 0.2;
    width: 102px;
    text-align: left;
  }
}