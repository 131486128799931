.language-switcher {
  img {
    border-radius: 2px;
    height: 20px;
    width: 30px;
  }

  cursor: pointer;
}

.disabled {
  filter: grayscale(100%);
}

.active {
  filter: grayscale(0);
}
