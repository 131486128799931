.media-photo {
  max-width: 100%;
}

.data-fields {
  button {
    width: 100%;
  }
}

.relation-list {
  margin-top: 10px;

  .pdf-icon {
    text-align: center;
    font-weight: bold;
    width: 100%;
    height: 100%;
    background: #fafafa;
    color: #4e4e4e;
    border-radius: 6px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 30px;

    img {
      width: 50px;
      margin: 0 auto;
    }
  }

  .item-image {
    display: block;
    width: 100%;
    height: 150px;
    background-position: center;
    background-size: cover;
    max-width: 100%;
    border-radius: 6px;
  }

  .url-copy {
    display: none;
  }

  .data-fields-custom {
    border-top: 1px solid #eee;
    padding: 5px 0;

    .content-right {
      text-align: right;
    }

    &:last-child {
      border-bottom: none;
    }

    .component-field-name {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      display: block;
      font-size: 14px;
      margin: 0;
    }

    .data-content {
      line-height: 30px;
      font-weight: 400;
      position: relative;

      .data-action {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #000;
        background: rgba(255, 255, 255, 0.4);
      }

      .copy-link {
        position: absolute;
        top: 10px;
        left: 80px;
        background: rgba(255, 255, 255, 0.4);
      }

      .view-action {
        position: absolute;
        top: 10px;
        left: 45px;
        color: #000;
        background: rgba(255, 255, 255, 0.4);
      }

      svg {
        width: 15px;
        height: 15px;
        padding: 5px;
      }
    }
  }
}
