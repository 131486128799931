.pagination {
  .pagination-button {
    background-color: #fff;
    border-radius: 0;
    box-shadow: 4px 0 4px 0 rgba(43, 45, 55, 0.01);
    height: 36px;
    min-width: 36px;
    border: 0;
    margin-right: 1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;

      &.active {
        color: #b0640c;
        opacity: 0.5;

        p {
          color: #b0640c;
        }
      }
    }
  }

  .left {
    border-radius: 4px 0 0 4px;
    margin-right: 1px;
    flex-direction: column;
  }

  .right {
    border-radius: 0 4px 4px 0;
    margin-right: 0;
    flex-direction: column;
  }

  .fixed-button {
    border-radius: 4px;
    margin-right: 0;
  }

  .numbered-pagination {
    padding: 0 5px;
    display: flex;
  }
}
