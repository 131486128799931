.selecting {
  background-color: rgba(176, 100, 12, 0.1);
  fill: #b0650c;
  fill-opacity: 90;
  border-radius: 4px 4px 0 0;
  padding: 15px 20px;
  color: #b0650c;
}

.header {
  margin: 0;
  width: 100%;
  height: 100%;
}

.title {
  padding: 5px 20px 5px 30px;
}
