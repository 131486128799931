.box-wrap {

  a.MuiButton-containedPrimary,
  a.MuiButton-containedPrimary:visited {
    color: #fff;
  }

  a,
  a:visited {
    color: var(--primary);
  }

  .MuiTablePagination-root:last-child {
    border-bottom: none;
  }

  .box-toolbar {
    z-index: 1;
    position: relative;
  }
}

.box-toolbar {
  &.box-toolbar--info {
    color: #2196f3;
  }

  svg {
    vertical-align: -6px;
  }
}

.box-info {
  font-size: 14px;
  font-weight: 300;
  color: #333333;

  code {
    background: #ffe8e8;
    padding: 3px 5px;
    border-radius: 3px;
    color: #e84747;
  }
}
