.no-boards {
  width: 100%;
  // padding: 150px;
  margin: 150px 0;
  box-sizing: border-box;
  max-width: 500px;

  span {
    color: var(--primary);
    cursor: pointer;
  }
}
