.input-error {
  svg {
    fill: #e4002b;
    vertical-align: -7px;
    padding-right: 5px;
  }

  span {
    font-size: 14px;
    font-weight: 300;
    color: #000;
  }
}
